/*
 * @Author: ghh 277611282@qq.com
 * @Date: 2022-05-24 11:10:43
 * @LastEditors: ghh 277611282@qq.com
 * @LastEditTime: 2022-05-24 11:22:46
 * @FilePath: \piano-web\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import Vuex from "vuex";
import { getCurrentInfo } from "@/api/user";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    theme: true,
    menuList: [],
    isAuth: false
  },
  getters: {},
  mutations: {
    themeStyle(state, val) {
      state.theme = val;
    },
    setMenuList(state, val) {
      state.menuList = val.split(',')
    },
    changeAuth(state) {
      state.isAuth = !state.isAuth
    }
  },
  actions: {
    changeThemeStyle({ state, commit }, init) {
      commit("themeStyle", init);
    },
    async getMenu({ state, commit }, data) {
      let list=localStorage.getItem('menuList')
      if(list){
        commit('setMenuList', list)
        commit('changeAuth')
      }else{
        console.log(111);
        let res = await getCurrentInfo()
        let list = res.data.data.menuList
        localStorage.setItem('menuList',list)
        commit('setMenuList', list)
        commit('changeAuth')
      }
    }
  },
  modules: {},
});
