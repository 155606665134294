import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import { getCurrentRouters } from "@/utils/xxx";
const index = (resolve) => require(["../views/index.vue"], resolve);
const homePage = (resolve) => require(["../views/home/homePage.vue"], resolve);
const purchaseMgr = (resolve) =>
  require(["../views/purchasesell/purchase/index.vue"], resolve);
const login = (resolve) => require(["../views/login.vue"], resolve);
const dealList = (resolve) =>
  require(["../views/finance/dealList/index.vue"], resolve);

const sales = (resolve) =>
  require(["../views/statement/Sales/index.vue"], resolve);
const CallBack = (resolve) =>
  require(["../views/statement/CallBack/index.vue"], resolve);
const IncomeStatement = (resolve) =>
  require(["../views/statement/IncomeStatement/index.vue"], resolve);
const AfterSale = (resolve) =>
  require(["../views/after/AfterSale/index.vue"], resolve);
const Inventory = (resolve) =>
  require(["../views/purchasesell/Inventory/index.vue"], resolve);
const SalesOrder = (resolve) =>
  require(["../views/purchasesell/SalesOrder/index.vue"], resolve);
const Jurisdiction = (resolve) =>
  require(["../views/administrator/jurisdiction/index.vue"], resolve);
const productsModel = (resolve) =>
  require(["../views/administrator/productsModel/index.vue"], resolve);
const user = (resolve) =>
  require(["../views/usermanger/user/index.vue"], resolve);
const userEdit = (resolve) =>
  require(["../views/usermanger/userEdit/index.vue"], resolve);
const clueList = (resolve) =>
  require(["../views/clue/clueList/index.vue"], resolve);

const news = (resolve) =>
  require(["../views/usermanger/news/index.vue"], resolve);
const Service = (resolve) =>
  require(["../views/statement/servicement/index.vue"], resolve);
const salesPersonnel = (resolve) =>
  require(["../views/after/salesPersonnel/index.vue"], resolve);
const employeeWorkOrder = (resolve) =>
  require(["../views/after/employeeWorkOrder/index.vue"], resolve);
const staffDetails = (resolve) =>
  require(["../views/after/staffDetails/index.vue"], resolve);


const administrator = (resolve) =>
  require(["../views/administrator/index.vue"], resolve);
const purchasesell = (resolve) =>
  require(["../views/purchasesell/index.vue"], resolve);
const statement = (resolve) =>
  require(["../views/statement/index.vue"], resolve);
const independent = (resolve) =>
  require(["../views/after/independent/index.vue"], resolve);
const after = (resolve) => require(["../views/after/index.vue"], resolve);
const finance = (resolve) => require(["../views/finance/index.vue"], resolve);
const clue = (resolve) => require(["../views/clue/index.vue"], resolve);
const NotFound = (resolve) => require(["../views/NotFound.vue"], resolve);
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
  },

  {
    path: "/not-found",
    name: "404",
    component: NotFound,
  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: { title: "登录", keepAlive: true },
  },
];

const dynamicRoutes = [
  {
    path: "/index",
    name: "index",
    component: index,
    redirect: { name: "homePage" },
    children: [
      {
        path: "/homePage",
        name: "homePage",
        component: homePage,
        meta: { title: "首页中心", icon: "el-icon-s-home" },
      },
      {
        path: "/administrator",
        name: "administrator",
        component: administrator,
        meta: { title: "管理员", id: "1" },
        children: [
          {
            path: "/jurisdiction",
            name: "jurisdiction",
            component: Jurisdiction,
            meta: { title: "员工管理", icon: "el-icon-s-tools", id: "11" },
          },
          {
            path: "/productsModel",
            name: "productsModel",
            component: productsModel,
            meta: { title: "分类管理", icon: "el-icon-menu", id: "12" },
          },
        ],
      },
      {
        path: "/purchasesell",
        name: "purchasesell",
        component: purchasesell,
        meta: { title: "进销存管理", id: "2" },
        children: [
          {
            path: "/purchase",
            name: "purchase",
            component: purchaseMgr,
            meta: {
              title: "进货管理",
              icon: "el-icon-s-cooperation",
              id: "21",
            },
          },
          {
            path: "/inventory",
            name: "inventory",
            component: Inventory,
            meta: { title: "库存管理", icon: "el-icon-s-grid", id: "22" },
          },
          {
            path: "/salesOrder",
            name: "salesOrder",
            component: SalesOrder,
            meta: { title: "销售订单", icon: "el-icon-s-order", id: "23" },
          },
        ],
      },
      {
        path: "/statement",
        name: "statement",
        component: statement,
        meta: { title: "产品销售报表", id: "3" },
        children: [
          {
            path: "/sales",
            name: "sales",
            component: sales,
            meta: { title: "产品销售报表", icon: "el-icon-s-data", id: "31" },
          },
          {
            path: "/callBack",
            name: "callBack",
            component: CallBack,
            meta: { title: "客户回款报表", icon: "el-icon-s-ticket", id: "32" },
          },
          {
            path: "/incomeStatement",
            name: "incomeStatement",
            component: IncomeStatement,
            meta: {
              title: "收支明细报表",
              icon: "el-icon-s-finance",
              id: "33",
            },
          },
          {
            path: "/service",
            name: "service",
            component: Service,
            meta: { title: "售后工单报表", icon: "el-icon-s-help", id: "34" },
          },
        ],
      },
      {
        path: "/after",
        name: "after",
        component: after,
        meta: { title: "售后管理", id: "4" },
        children: [
          {
            path: "/afterSale",
            name: "afterSale",
            component: AfterSale,
            meta: { title: "售后工单", icon: "el-icon-warning", id: "41" },
          },
          {
            path: "/salesPersonnel",
            name: "salesPersonnel",
            component: salesPersonnel,
            meta: { title: "售后人员", icon: "el-icon-user-solid", id: "42" },
          },
          {
            path: "/independent",
            name: "independent",
            component: independent,
            meta: { title: "往期/非销售售后", icon: "el-icon-user-solid", id: "43" },
          },
          {
            path: "/employeeWorkOrder",
            name: "employeeWorkOrder",
            component: employeeWorkOrder,
            meta: { title: "员工售后工单", icon: "el-icon-user-solid", id: "44" },
          },
          {
            path: "/staffDetails",
            name: "staffDetails",
            component: staffDetails,
            meta: { title: "员工详情", icon: "el-icon-user-solid", id: "45" },
          },
        ],
      },
      {
        path: "/finance",
        name: "finance",
        component: finance,
        meta: { title: "财务中心", id: "5" },
        children: [
          {
            path: "/dealList",
            name: "dealList",
            component: dealList,
            meta: { title: "收支明细", icon: "el-icon-s-marketing", id: "51" },
          },
        ],
      },
      {
        path: "/usermanage",
        name: "usermanage",
        component: finance,
        meta: { title: "客户管理", id: "6" },
        children: [
          {
            path: "/user",
            name: "user",
            component: user,
            meta: { title: "客户管理", icon: "el-icon-user", id: "61" },
          },
          {
            path: "/userEdit",
            name: "userEdit",
            component: userEdit,
            meta: { title: "客户详情", id: "62" },
          },
          {
            path: "/news",
            name: "news",
            component: news,
            meta: { title: "消息提醒", icon: "el-icon-chat-line-round", id: "63" },
          },

        ],
      },
      {
        path: "/clue",
        name: "clue",
        component: clue,
        meta: { title: "线索管理", id: "7" },
        children: [
          {
            path: "/clueList",
            name: "clueList",
            component: clueList,
            meta: { title: "线索列表", icon: "el-icon-tickets", id: "71" },
          },
        ],
      },
    ],
  },
];

let aaaRoutes = [
  {
    path: "/index",
    name: "index",
    component: index,
    redirect: "/homePage",
    children: [],
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("token");
  if (token) {
    if (!store.state.isAuth) {
      await store.dispatch("getMenu");
      let list = await store.state.menuList;
      let children = getCurrentRouters(dynamicRoutes[0].children, list);
      aaaRoutes[0].children = children;
      aaaRoutes.push({
        path: "*",
        redirect: { name: "404" },
      });
      aaaRoutes.forEach((a) => {
        router.addRoute(a);
      });
      next({ ...to, replace: true })
    } else {
      next();
    }
  } else if (to.path === "/") {
    next();
  } else {
    next("/");
  }
});

// router.afterEach(to => {
//   sessionStorage.setItem('routerTo', JSON.stringify(to))
// })

export default router;
