import req from "@/utils/request";

// 密码登录
export function login(query) {
  return req.post("/dealer/password/login", query);
}

// 验证码登录
export function verifyCode(query) {
  return req.post("/dealer/verifyCode/login", query);
}

// 获取手机验证码
export function sms(query) {
  return req.post("/dealer/sms", query);
}

// 退出登录
export function logout(query) {
  return req.post("/dealer/logout", query);
}

// 上传图片
export function file(query) {
  return req.post("/dealer/file", query);
}

// 员工列表
export function getDealerUserList(query) {
  return req.post("/dealer/dealerUser/getDealerUserList", query);
}

// 添加员工
export function insertDealerUser(query) {
  return req.post("/dealer/dealerUser/insertDealerUser", query);
}

// 修改员工
export function updateDealerUser(query) {
  return req.post("/dealer/dealerUser/updateDealerUser", query);
}

// 查看员工
export function getDealerUserById(query) {
  return req.post("/dealer/dealerUser/getDealerUserById", query);
}

// 删除员工
export function deleteDealerUser(query) {
  return req.post("/dealer/dealerUser/deleteDealerUser", query);
}

// 重置员工密码
export function resetPassword(query) {
  return req.post("/dealer/dealerUser/resetPassword", query);
}

// 分类列表
export function getCategoryTree(query) {
  return req.post("/dealer/productCategory/getCategoryTree", query);
}

// 型号列表
export function getCategoryList(query) {
  return req.post("/dealer/productCategory/getCategoryList", query);
}

// 新增分类
export function insertCategory(query) {
  return req.post("/dealer/productCategory/insertCategory", query);
}

// 修改分类
export function updateCategory(query) {
  return req.post("/dealer/productCategory/updateCategory", query);
}

// 删除分类
export function deleteCategory(query) {
  return req.post("/dealer/productCategory/deleteCategory", query);
}

// 进货列表
export function getBuyList(query) {
  return req.post("/dealer/dealerBuy/getBuyList", query);
}

// 厂家列表
export function getFactoryList(query) {
  return req.post("/dealer/dealerFactory/getFactoryList", query);
}

// 新增厂家
export function insertFactory(query) {
  return req.post("/dealer/dealerFactory/insertFactory", query);
}

// 修改厂家
export function updateFactory(query) {
  return req.post("/dealer/dealerFactory/updateFactory", query);
}

// 删除厂家
export function deleteFactory(query) {
  return req.post("/dealer/dealerFactory/deleteFactory", query);
}

// 新增进货
export function insertBuy(query) {
  return req.post("/dealer/dealerBuy/insertBuy", query);
}

// 查询所有厂家
export function getAllFactoryList(query) {
  return req.post("/dealer/dealerFactory/getAllFactoryList", query);
}

// 查询所有厂家
export function getBuyById(query) {
  return req.post("/dealer/dealerBuy/getBuyById", query);
}

// 删除进货记录
export function deleteBuy(query) {
  return req.post("/dealer/dealerBuy/deleteBuy", query);
}

// 删除进货记录明细产品
export function deleteBuyDetailProduct(query) {
  return req.post("/dealer/dealerBuy/deleteBuyDetailProduct", query);
}

// 修改进货记录
export function updateBuy(query) {
  return req.post("/dealer/dealerBuy/updateBuy", query);
}

// 查看库存树
export function getProductTree(query) {
  return req.post("/dealer/dealerProduct/getProductTree", query);
}

// 客户列表
export function getCustomerList(query) {
  return req.post("/dealer/dealerCustomer/getCustomerList", query);
}

// 新增客户
export function insertCustomer(query) {
  return req.post("/dealer/dealerCustomer/insertCustomer", query);
}

// 修改客户
export function updateCustomer(query) {
  return req.post("/dealer/dealerCustomer/updateCustomer", query);
}

// 删除客户
export function deleteCustomer(query) {
  return req.post("/dealer/dealerCustomer/deleteCustomer", query);
}

// 存库详情列表
export function getProductRecordList(query) {
  return req.post("/dealer/dealerProduct/getProductRecordList", query);
}

// 产品编号列表
export function getProductNoList(query) {
  return req.post("/dealer/dealerProduct/getProductNoList", query);
}

// 设置产品编号
export function setProductNo(query) {
  return req.post("/dealer/dealerProduct/setProductNo", query);
}

// 查询所有客户
export function getAllCustomerList(query) {
  return req.post("/dealer/dealerCustomer/getAllCustomerList", query);
}

// 查询所有员工
export function getAllDealerUserList(query) {
  return req.post("/dealer/dealerUser/getAllDealerUserList", query);
}

// 新增销售订单
export function insertSell(query) {
  return req.post("/dealer/dealerSell/insertSell", query);
}

// 查询编号
export function getNotOutProductList(query) {
  return req.post("/dealer/dealerProduct/getNotOutProductList", query);
}

// 查询编号
export function getSellList(query) {
  return req.post("/dealer/dealerSell/getSellList", query);
}

// 删除销售单
export function deleteSell(query) {
  return req.post("/dealer/dealerSell/deleteSell", query);
}

// 查看销售单详情
export function getSellById(query) {
  return req.post("/dealer/dealerSell/getSellById", query);
}

// 修改销售订单
export function updateSell(query) {
  return req.post("/dealer/dealerSell/updateSell", query);
}

// 删除销售订单明细
export function deleteSellDetailProduct(query) {
  return req.post("/dealer/dealerSell/deleteSellDetailProduct", query);
}

// 添加售后人员
export function insertPostUser(query) {
  return req.post("/dealer/postUser/insertPostUser", query);
}

// 售后人员列表
export function getPostUserList(query) {
  return req.post("/dealer/postUser/getPostUserList", query);
}

// 售后人员详情
export function getPostUserById(query) {
  return req.post("/dealer/postUser/getPostUserById", query);
}

// 修改售后人员
export function updatePostUser(query) {
  return req.post("/dealer/postUser/updatePostUser", query);
}

// 删除售后人员
export function deletePostUser(query) {
  return req.post("/dealer/postUser/deletePostUser", query);
}

// 新增收支明细
export function insertFinance(query) {
  return req.post("/dealer/dealerFinance/insertFinance", query);
}

// 收支明细列表
export function getFinanceList(query) {
  return req.post("/dealer/dealerFinance/getFinanceList", query);
}

// 删除收支明细
export function deleteFinance(query) {
  return req.post("/dealer/dealerFinance/deleteFinance", query);
}

// 修改收支明细
export function updateFinance(query) {
  return req.post("/dealer/dealerFinance/updateFinance", query);
}

// 客户回款报表
export function getCustomerAmountRecord(query) {
  return req.post("/dealer/report/getCustomerAmountRecord", query);
}

// 收支明细报表
export function getFinanceAmountRecord(query) {
  return req.post("/dealer/report/getFinanceAmountRecord", query);
}

// 售后工单报表
export function getPostOrderRecord(query) {
  return req.post("/dealer/report/getPostOrderRecord", query);
}

// 销售数量报表
export function getSellQuantityRecord(query) {
  return req.post("/dealer/report/getSellQuantityRecord", query);
}

// 销售金额报表
export function getSellAmountRecord(query) {
  return req.post("/dealer/report/getSellAmountRecord", query);
}

// 工单产品列表
export function getProductWorkOrderList(query) {
  return req.post("/dealer/dealerWorkOrder/getProductWorkOrderList", query);
}

// 新建工单
export function insertWorkOrder(query) {
  return req.post("/dealer/dealerWorkOrder/insertWorkOrder", query);
}

// 工单列表
export function getWorkOrderList(query) {
  return req.post("/dealer/dealerWorkOrder/getWorkOrderList", query);
}

// 所有售后人员
export function getAllPostUserList(query) {
  return req.post("/dealer/postUser/getAllPostUserList", query);
}

// 分配工单
export function distributeWorkOrder(query) {
  return req.post("/dealer/dealerWorkOrder/distributeWorkOrder", query);
}

// 工单详情
export function getWorkOrderById(query) {
  return req.post("/dealer/dealerWorkOrder/getWorkOrderById", query);
}

// 更新销售订单明细
export function updateSellDetail(query) {
  return req.post("/dealer/dealerSell/updateSellDetail", query);
}

// 新增销售订单明细
export function insertSellDetail(query) {
  return req.post("/dealer/dealerSell/insertSellDetail", query);
}


// 获取权限菜单
export function getCurrentRouter(query) {
  return req.post("/dealer/dealerUser/getCurrentRouter", query);
}

// 获取当前管理员权限菜单
export function getCurrentInfo(query) {
  return req.post("/dealer/dealerUser/getCurrentInfo", query);
}

// 取消工单
export function cancelWorkOrder(query) {
  return req.post("/dealer/dealerWorkOrder/cancelWorkOrder", query);
}

// 导入工单
export function importWorkOrder(query) {
  return req.post("/dealer/dealerWorkOrder/importWorkOrder", query);
}

// 非销售产品列表
export function getProductHistoryOrderList(query) {
  return req.post("/dealer/dealerHistoryOrder/getProductHistoryOrderList", query);
}

// 非销售工单列表
export function getHistoryOrderList(query) {
  return req.post("/dealer/dealerHistoryOrder/getHistoryOrderList", query);
}

// 新建非销售工单
export function insertHistoryOrder(query) {
  return req.post("/dealer/dealerHistoryOrder/insertHistoryOrder", query);
}

// 查看非销售工单详情
export function getHistoryOrderById(query) {
  return req.post("/dealer/dealerHistoryOrder/getHistoryOrderById", query);
}

// 分配非销售工单
export function distributeHistoryOrder(query) {
  return req.post("/dealer/dealerHistoryOrder/distributeHistoryOrder", query);
}

// 取消非销售工单
export function cancelHistoryOrder(query) {
  return req.post("/dealer/dealerHistoryOrder/cancelHistoryOrder", query);
}

// 删除非销售产品
export function deleteHistoryProduct(query) {
  return req.post("/dealer/dealerHistoryOrder/deleteHistoryProduct", query);
}

// 批量新建工单
export function batchInsertWorkOrder(query) {
  return req.post("/dealer/dealerWorkOrder/batchInsertWorkOrder", query);
}

// 批量分配工单
export function batchDistributeWorkOrder(query) {
  return req.post("/dealer/dealerWorkOrder/batchDistributeWorkOrder", query);
}


// 批量新建导入工单
export function batchInsertHistoryOrder(query) {
  return req.post("/dealer/dealerHistoryOrder/batchInsertHistoryOrder", query);
}

// 批量分配导入工单
export function batchDistributeHistoryOrder(query) {
  return req.post("/dealer/dealerHistoryOrder/batchDistributeHistoryOrder", query);
}

// 首页数据
export function getIndexReport(query) {
  return req.post("/dealer/report/getIndexReport", query);
}

// 工单状态变更
export function rebackWorkOrder(query) {
  return req.post("/dealer/dealerWorkOrder/rebackWorkOrder", query);
}

// 工单状态变更
export function rebackHistoryOrder(query) {
  return req.post("/dealer/dealerHistoryOrder/rebackHistoryOrder", query);
}

// 查询销售数据月度汇总报表
export function getSellMonthRecord(query) {
  return req.post("/dealer/report/getSellMonthRecord", query);
}

// 查询客户分析列表
export function getCustomerDataList(query) {
  return req.post("/dealer/dealerCustomer/getCustomerDataList", query);
}

// 查询出入库记录
export function getProductAccessList(query) {
  return req.post("/dealer/dealerProduct/getProductAccessList", query);
}

// 确认入库
export function confirmIn(query) {
  return req.post("/dealer/dealerProduct/confirmIn", query);
}

// 进货退回
export function backIn(query) {
  return req.post("/dealer/dealerProduct/backIn", query);
}

// 查询预警列表
export function getInventoryAlarmPage(query) {
  return req.post("/dealer/dealerInventoryAlarm/getInventoryAlarmPage", query);
}

// 忽略预警
export function ignoreInventoryAlarm(query) {
  return req.post("/dealer/dealerInventoryAlarm/ignoreInventoryAlarm", query);
}

// 查询预定订单列表
export function getPreSellList(query) {
  return req.post("/dealer/dealerPreSell/getPreSellList", query);
}

// 查询预定订单详情
export function getPreSellById(query) {
  return req.post("/dealer/dealerPreSell/getPreSellById", query);
}

// 删除预定订单
export function deletePreSell(query) {
  return req.post("/dealer/dealerPreSell/deletePreSell", query);
}

// 新增预定订单
export function insertPreSell(query) {
  return req.post("/dealer/dealerPreSell/insertPreSell", query);
}

// 转为销售订单
export function transformSell(query) {
  return req.post("/dealer/dealerPreSell/transformSell", query);
}

// 修改预定订单
export function updatePreSell(query) {
  return req.post("/dealer/dealerPreSell/updatePreSell", query);
}

// 批量发送消息
export function batchSendMessage(query) {
  return req.post("/dealer/dealerCustomer/batchSendMessage", query);
}

// 取消预定订单
export function cancelPreSell(query) {
  return req.post("/dealer/dealerPreSell/cancelPreSell", query);
}

// 查询进货合计
export function getBuySum(query) {
  return req.post("/dealer/dealerBuy/getBuySum", query);
}

// 查询进货月度汇总报表
export function getBuyMonthRecord(query) {
  return req.post("/dealer/report/getBuyMonthRecord", query);
}

// 查询客户消息列表
export function getTemplateMsgList(query) {
  return req.post("/dealer/dealerTemplateMsg/getTemplateMsgList", query);
}

// 查询客户消息详情
export function getTemplateMsgById(query) {
  return req.post("/dealer/dealerTemplateMsg/getTemplateMsgById", query);
}

// 查询客户详情
export function getCustomerById(query) {
  return req.post("/dealer/dealerCustomer/getCustomerById", query);
}

// 修改非销售产品编号
export function updateHistoryProductNo(query) {
  return req.post("/dealer/dealerHistoryOrder/updateHistoryProduct", query);
}

// 有效-失效产品
export function invalidWorkOrderProduct(query) {
  return req.post("/dealer/dealerWorkOrder/invalidWorkOrderProduct", query);
}

// 查询客户数量分析列表
export function getCustomerTypeQuantityList(query) {
  return req.post("/dealer/dealerCustomer/getCustomerTypeQuantityList", query);
}

// 获取工单线索分页
export function getOrderCluePage(query) {
  return req.post("/dealer/dealerOrderClue/getOrderCluePage", query);
}

// 获取工单线索详情
export function getOrderClueById(query) {
  return req.post("/dealer/dealerOrderClue/getOrderClueById", query);
}

// 处理工单线索
export function updateOrderClue(query) {
  return req.post("/dealer/dealerOrderClue/updateOrderClue", query);
}

// 有效-失效产品
export function invalidHistoryOrderProduct(query) {
  return req.post("/dealer/dealerHistoryOrder/invalidHistoryOrderProduct", query);
}

// 查询公共工单列表(合并售后工单和非销售工单)
export function getCommonOrderList(query) {
  return req.post("/dealer/postUser/getCommonOrderList", query);
}

// 查询订单数量报表
export function getOrderQuantityRecord(query) {
  return req.post("/dealer/postUser/getOrderQuantityRecord", query);
}

// 查询订单项目统计报表
export function getOrderProjectQuantityRecord(query) {
  return req.post("/dealer/postUser/getOrderProjectQuantityRecord", query);
}

// 批量确认入库
export function confirmBatchIn(query) {
  return req.post("/dealer/dealerProduct/confirmBatchIn", query);
}