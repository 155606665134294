import axios from "axios";
import CryptoJS from "crypto-js";
import { Message } from "element-ui";
import router from "vue-router";
// boyandms/dealer/
// http://192.168.5.16:58040
// axios.defaults.baseURL = "https://dealer.boyanmusic.com";
// axios.defaults.baseURL = "https://boyanmusic.com";
axios.defaults.baseURL = "/";
axios.defaults.timeout = 30000;
// 请求拦截器
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  // config.headers.responseType = "blob";
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});


// 响应拦截器
axios.interceptors.response.use(
  (res) => {
    if (res.data.code == 401) {
      Message({
        type: "error",
        message: res.data.msg,
      });
      localStorage.clear();
      location.href = '/login';
    }
    return res;
  },
  (error) => {
    // console.log(error.code)
    return error;
  }
);

export default {
  get(url, params) {
    // localStorage.setItem("sign", sign);
    return new Promise((resolve, reject) => {
      axios
        .get(url, { params })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          // 处理失败的请求
          console.log(err);
        });
    });
  },
  post(url, query) {
    let obj = {}
    for (let k in query) {
      if (query[k] || query[k] === 0) {
        obj[k] = query[k]
      }
    }
    obj.signKey = "ZnJhbWUtZW5jcnlwdC1rZXk=";
    const arr = [];
    for (const k in obj) {
      if (k === "idList" || k === "productIdList" || k === "detailIdList") {
        let v = "[";
        obj[k].forEach((res) => {
          v = v + '"' + res + '"' + ",";
        });
        v = v.substring(0, v.length - 1);
        arr.push(k + "=" + v + "]");
      } else if (k === "productNoList") {
        let v = "[";
        obj[k].forEach((res) => {
          let s = "{";
          for (let k in res) {
            s = s + '"' + k + '"' + ":" + '"' + res[k] + '"' + ",";
          }
          s = s.substring(0, s.length - 1) + "}";
          v = v + s + ",";
        });
        v = v.substring(0, v.length - 1);
        arr.push(k + "=" + v + "]");
      } else if (k === 'detailList' || k === 'customerIds') {
        arr.push(k + "=" + JSON.stringify(obj[k]));
      } else {
        arr.push(k + "=" + obj[k]);
      }
    }
    console.log(arr.sort().join("&"));
    const sign = CryptoJS.SHA256(arr.sort().join("&"));
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url,
        data: query,
        headers: {
          sign: sign
        }
      }).then((res) => {
        resolve(res);
      })
        .catch((err) => {
          console.log(err);
        });
    });
  },
  delete(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .delete(url, { params })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          // 处理失败的请求
          console.log(err);
        });
    });
  },
};
