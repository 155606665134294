import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/styles/app.less";
import "./assets/styles/theme.less";
import echarts from "echarts";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import globalComponents from "./components/globalComponents";
import "lib-flexible/flexible";
import JsonExcel from "vue-json-excel";
import Print from 'vue-print-nb'

Vue.use(Print);  //注册
Vue.component("downloadExcel", JsonExcel);
Vue.use(ElementUI);
Vue.use(globalComponents);
Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;

Vue.prototype.$store = store;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
